import React, { useState } from "react"

import { PageProps } from "gatsby"
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';
import Layout from "../../components/layout"
import '../../styles/live-session.css'

const sections = [
    {
        start: '364',
        end: '377',
        component: () => {
            return (<>
                This was a vscode live share stream where random people needed to develop,
                a todo list in less than 30 minutes.
                <br />
                <br />
                In this first part of the video, I suggested using nextjs, the reason behind that
                is because nextjs handles the router automatically this would have allowed me to
                create a independent component fast.
            </>)
        }
    },
    {
        start: '749',
        end: '783',
        component: () => {
            return (<>
                Here I was trying to set a first proposition of the base component for the app,
                using comments to communicate with people. Also, I chose to write this on the 
                index file because I tought people were going to be there and I can write some
                think to talk to them and organize.
                <br />
                <br />
                What I meant by we need CSS is that I wanted somebody to say that he was going
                own the CSS of the project. I was the first one to write code so I took a leadership position 
            </>)
        }
    },
    {
        start: '848',
        end: '875',
        component: () => {
            return (<>
                After trying to make people comment, I found out that It wasn't going to work
                the way that I intended so I started building what we needed myself, interface
                for the todo items and an Item array to map
            </>)
        }
    },
    {
        start: '966',
        end: '1009',
        component: () => {
            return (<>
                Finally, somebody started helping him create the same variable that I created before
                but he was helping so instead of worrying about that part of the state I started creating
                the todo item component
            </>)
        }
    },
    {
        start:'1042',
        end: '1059',
        component: () => {
            return (<>
                People were not having my component division, they wanted everything inside the
                function so instead of fighting with them I join them and delete the code that was not
                utilize and start putting the valuable work inside what they were working on 
                <br/>
                <br />
                The video doesn't show but I paste the code that I delete from the deleted base component
                on the body that they where creating
            </>)
        }
    },
    {
        start: '1318',
        end: '1336',
        component: () => {
            return (<>
                This is the great part of collaboration Timur started creating the missing form,
                to request the todo item
            </>)
        }
    },
    {
        start: '1522',
        end: '1569',
        component: () => {
            return (<>
                The dead line was coming I started utilizing Timur state functions of the todo Items,
                it was confusing trying to understand what people were doing to the codebase and
                getting something to work
            </>)
        }
    },
    {
        start: '1943',
        end: '1990',
        component: () => {
            return (<>
                With less than 9 minutes I save the changes to see what the state of the app was
                <br/>
                <br/>
                Alex tested the app and I remember why I asked for somebody to take care of the CSS,
                the done onClick was working at a state level but it was missing something to showcase that
            </>)
        }
    },
    {
        start: '2363',
        end: '2432',
        component: () => {
            return (<>
                We made it check it out
            </>)
        }
    }
]

export default function (props: PageProps) {
    const [sectionId, setSectionId] = useState(0);

    return (
        <Layout title="Blog">
            <div className="container">
                <iframe className="video"
                    src={`https://www.youtube.com/embed/CWToVo5fjKI?start=${sections[sectionId].start}&end=${sections[sectionId].end}`}
                    title="YouTube video player"
                    frameBorder="0">
                </iframe>
            </div>
            <div className="mt-3">
                {sections[sectionId].component()}
            </div>

            <div className="mt-3 flex justify-between">
                {sectionId > 0 ?
                    (<button className="button" onClick={() => setSectionId(sectionId - 1)}>
                        <FaChevronLeft/>
                    </button>) : 
                    (<div></div>)
                }
                {sectionId+1 < sections.length ?
                    (<button className="button" onClick={() => setSectionId(sectionId + 1)}>
                        <FaChevronRight />
                    </button>) :
                    (<div></div>)
                }
            </div>
        </Layout>
    )
}